<template>
  <div v-if="isVisible" class="staging-instance-banner">
    <div class="staging-instance-banner__content">
      <strong>This is staging</strong>
      <span>You are using test data.</span>
      <strong>This is staging</strong>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, watchEffect } from 'vue'
import { useRoute } from 'vue-router'

import { getConfigForFeature } from '@/lib/appConfig'

const route = useRoute()

const isVisible = computed(() => {
  return (
    getConfigForFeature('isProductionInstance') === false &&
    route.name !== 'choose-agency'
  )
})

watchEffect(() => {
  if (isVisible.value) {
    document.body.classList.add('staging-instance-banner-visible')
  } else {
    document.body.classList.remove('staging-instance-banner-visible')
  }
})
</script>

<style lang="scss" scoped>
.staging-instance-banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 5px 20px 5px 80px;
  z-index: 3;

  background-color: var(--el-color-warning-light-3);

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
