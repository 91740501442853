<template>
  <div class="dialog-header">
    <ElIcon v-if="icon" size="24px" class="dialog-header__icon">
      <Component :is="icon" />
    </ElIcon>

    <Heading tag="h2" size="normal" bold> <slot /> </Heading>
    <SimpleText
      tag="h3"
      size="micro"
      type="secondary"
      class="dialog-header__subheading"
    >
      <slot name="subheader" />
    </SimpleText>
  </div>
</template>

<script lang="ts" setup>
import Heading from '@/patterns/Heading/Heading.vue'

import SimpleText from '../SimpleText/SimpleText.vue'
interface Props {
  icon?: object
}

defineProps<Props>()
</script>

<style lang="scss" scoped>
.dialog-header {
  display: grid;
  grid-template-columns: min-content 1fr;

  grid-template-areas:
    'icon heading'
    'subheading subheading';

  align-items: flex-start;

  &__icon {
    grid-area: icon;
    margin-right: 0.5rem;
  }

  &__subheading {
    margin: 0;
    grid-area: subheading;
  }
}
</style>
