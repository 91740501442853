<template>
  <div class="error-context-wrapper">
    <SimpleText class="title" tag="div" size="small" bold> Context </SimpleText>

    <ul class="fields-wrapper">
      <li v-for="field in fields" :key="field.label">
        <SimpleText type="secondary" class="label" size="micro" bold>
          {{ field.label }}:&nbsp;
        </SimpleText>
        <SimpleText type="secondary" class="value" size="micro">
          {{ field.value }}
        </SimpleText>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { ErrorContext } from '@/domain/Error/ErrorContext'
import SimpleText from '@/patterns/SimpleText'

interface ErrorDetailField {
  label: string
  value: string
}

const contextKeyToFieldLabel = {
  status: 'Status code',
  timeOfError: 'Time of Error',
  customerId: 'Customer id',
  customerUid: 'Customer uid',
  orderUid: 'Order id',
  details: 'Details',
  agency: 'Agency',
  instance: 'Instance',
  version: 'App Version',

  url: 'Api Url',
}

interface Props {
  context: ErrorContext
}

const props = defineProps<Props>()

const fields = computed((): ErrorDetailField[] => {
  const result: ErrorDetailField[] = []

  for (const [key, value] of Object.entries(contextKeyToFieldLabel)) {
    const contextValue = props.context?.[key as keyof ErrorContext]

    if (contextValue) {
      result.push({
        label: value,
        value: contextValue.toString(),
      })
    }
  }

  return result
})
</script>

<style lang="scss" scoped>
.fields-wrapper {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding-left: var(--app-spacing-2);
}

.title {
  margin-bottom: var(--app-spacing-1);
}

.label {
  min-width: 200px;
  flex-shrink: 0;
}
</style>
