<template>
  <ElConfigProvider size="large">
    <div id="app" class="app">
      <ErrorDialogNew />
      <ElContainer v-if="isReady">
        <a :class="getStyleFromAgency" href="#main-content">
          Skip to content
        </a>
        <ElAside width="64px">
          <TheSiteHeader />
        </ElAside>
        <ElContainer>
          <ElHeader>
            <StagingInstanceBanner />
            <CallComponent />
          </ElHeader>
          <RouterView id="main-content" />
        </ElContainer>
      </ElContainer>
    </div>
  </ElConfigProvider>
</template>

<script setup lang="ts">
import { ElLoading } from 'element-plus'
import { ref, watch, inject, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import type { InstanceName, AgencyName } from '@/@types/Config'
import CallComponent from '@/components/CallComponent'
import ErrorDialogNew from '@/components/ErrorDialogNew/ErrorDialogNew.vue'
import StagingInstanceBanner from '@/components/StagingInstanceBanner'
import TheSiteHeader from '@/components/TheSiteHeader'
import usePageViewers from '@/composables/usePageViewers'
import {
  usePermissions,
  setPermissionsAccessToken,
} from '@/composables/usePermissions'
import useSurcharges from '@/composables/useSurcharges'
import { isValidInstanceAndAgencyCombination } from '@/config'
import {
  getConfigForFeature,
  getLdConfig,
  loadLaunchDarklyAndInitializeConfig,
} from '@/lib/appConfig'
import { useAuth0 } from '@/lib/auth0/auth0-vue-proxy'
import { getBuildVersion } from '@/lib/build'
import { initialiseHeap } from '@/lib/heap'
import { setHeapContext, setSentryContext } from '@/lib/integrations'

const auth0 = useAuth0()
const route = useRoute()
const { roles, permissions } = usePermissions()
const { loadSurcharges } = useSurcharges()
const { locale } = useI18n()

const pageViewers = usePageViewers()

const agency = inject('agency') as AgencyName
const instance = inject('instance') as InstanceName
const plrn = inject('plrn') as string
const isReady = ref(false)

const loading = ElLoading.service({
  text: 'Authenticating',
  background: '#fff',
  fullscreen: true,
  lock: true,
})

const getStyleFromAgency = computed(() => {
  const theme =
    getConfigForFeature('isProductionInstance') === false ? 'light' : 'dark'

  return `skip-navigation skip-navigation-theme-${theme}`
})

// Set agency after authentication
watch(
  () => auth0.user?.value?.email,
  async (email) => {
    if (email) {
      // Set permissions access token
      const accessToken = await auth0.getAccessTokenSilently()
      setPermissionsAccessToken(accessToken)

      // Load LaunchDarkly and initialize config
      if (isValidInstanceAndAgencyCombination(instance, agency)) {
        loading.setText(`Initialising ${instance}`)
        await loadLaunchDarklyAndInitializeConfig(email, {
          agency,
          instance,
        })
      }

      // Set locale
      const selectedLanguage = getConfigForFeature('locale')
      if (selectedLanguage && typeof selectedLanguage === 'string') {
        locale.value = selectedLanguage
      } else {
        locale.value = 'en'
      }

      const identityContext = {
        plrn,
        agency,
        instance,
        roles: roles.value,
        permissions: permissions.value,
        userId: auth0.user.value.sub?.replace('auth0|', ''),
        buildVersion: getBuildVersion(),
        ldConfig: getLdConfig(),
        isDeveloper: roles.value.some((role) => role.includes('Devs')),
        isCustomerSupportUser: roles.value.some((role) => role.includes('CS')),
        isClaimsUser: roles.value.some((role) => role.includes('Claim')),
      }

      // Load Heap
      const heapEnabled =
        Boolean(getConfigForFeature('heap.is-enabled')) &&
        import.meta.env.MODE !== 'development' &&
        // Disable Heap if production instance and user is a developer
        !(
          getConfigForFeature('isProductionInstance') &&
          identityContext.isDeveloper
        )
      const heapEnvironmentId = getConfigForFeature('heap.environmentId')
      if (heapEnabled && heapEnvironmentId !== null) {
        initialiseHeap(heapEnvironmentId as string)
      }

      setSentryContext(identityContext)
      setHeapContext(identityContext)
      if (agency) {
        loadSurcharges()
      }

      // Ready to display contetn and hide the loader
      isReady.value = true
      loading.close()

      if (getConfigForFeature('page-viewers.is-enabled')) {
        await pageViewers.start()
      }
    }
  },
  {
    immediate: true,
  }
)

// Switch between legacy and element-plus root classes
watch(
  () => route.meta?.legacy,
  (isLegacyRoute) => {
    if (isLegacyRoute) {
      document.documentElement.classList.add('legacy')
      document.documentElement.classList.remove('element-ui')
    } else {
      document.documentElement.classList.add('element-ui')
      document.documentElement.classList.remove('legacy')
    }
  },
  {
    immediate: true,
  }
)
</script>

<style lang="scss">
@use 'sass:meta';

@include meta.load-css('sass/site');

// For smooth scrolling to ElForm validation errors when used with "scroll-to-error" prop
html,
.el-overlay-dialog {
  scroll-behavior: smooth;
}

body,
html {
  height: 100%;
}

.app {
  display: flex;
  min-height: 100vh;
}

/* The animation code */
@keyframes move-down {
  0% {
    transform: translateY(-120%);
  }

  100% {
    transform: translateY(0%);
  }
}

.skip-navigation {
  padding: var(--app-spacing-2) var(--app-spacing-5);
  position: absolute;
  z-index: 100;
  transform: translateY(-120%);
  margin-left: 59px;
  border-radius: var(--app-spacing-1);

  &:focus-visible,
  &:focus {
    animation-name: move-down;
    animation-duration: 0.5s;
    transform: translateY(0%);
  }

  &-theme-light {
    background: var(--el-menu-bg-color);
    color: white;

    a:focus-visible,
    a:focus {
      background: var(--el-menu-bg-color);
      color: white;
    }
  }

  &-theme-dark {
    background: var(--el-color-primary);
    color: white !important;

    a:focus-visible,
    a:focus {
      background: var(--el-color-primary);
      color: white !important;
    }
  }
}
</style>
