<template>
  <ElIcon class="image-icon" aria-label="User">
    <img
      width="24"
      height="24"
      :src="user.picture"
      :alt="`Avatar of ${user.nickname || 'user'}`"
      class="avatar el-avatar--circle"
    />
  </ElIcon>
</template>

<script setup lang="ts">
interface Props {
  user: { nickname?: string; picture?: string }
}
defineProps<Props>()
</script>
