import { AgencyConfig } from '@/@types/Config'
import deepFreeze from '@/lib/utils/deepFreeze'

const agencyConfig: AgencyConfig = {
  flags: {
    allowCreatingNewClaim: true,
    allowEditProductAtRenewal: true,
    ibsuiteSync: true,
    usePca: true,
    showCalculationsList: true,
    customerNotesEnabled: true,
    allowEditClaimTitle: true,
    allowAddLossRecoveredAmount: true,
    allowDocumentRequestFromVet: true,
    allowDifferentPostcodes: false,
    dobAsRatingFactor: true,
    blockQuoting: false,
    enableNewFlow: true, // will be used to force SGP enable for staging
    forceDisableNewFlow: false,
    enableEmailOrder: false,
    nudgeForNewFlow: false,
    disableOldQuotingFlow: false,
    displayCancellationWarningText: true,
    displayCustomerFlagsDropdown: true,
    displayFullAddressOnNewBusiness: true,
    allowEditLegacyCustomer: false,
    disableEditEffectiveDate: false,
    disableEditInceptionDate: false,
    enableNewBusinessNextDayInceptionDate: false,
    enableNewDocumentsTab: true,
    removeSubscriptionsTab: true,
    displayWaitingPeriod: false,
    enableCommunication: true,
    enableUserHistory: false,
    enableRetainedPremiumsCalculations: false,
    enableReinstatement: true,
    enableSendingAffiliateCode: true,
    enableResetSmsMfa: true,
    enableNewRenewalUi: true,
    newFinancialTabIsEnabled: true,
    enableCerfScore: true,
    ibaPetTimelineEnabled: true,
    enableNewBusinessEditPaymentDate: true,
    enableCurrentBusinessEditPaymentDate: true,
    isPciPalEnabled: true,
  },
  currency: {
    code: 'GBP',
    symbol: '£',
    symbolPosition: 'prepend',
  },
  copaymentType: 'co-payment',
  excessType: 'excess',
  locale: 'en-GB',
  dateFormatForLocale: 'D MMM YYYY',
  algolia: {
    breedLookup: {
      id: 'ORKMXLHYI4',
      index: 'dev_cat_dog_pio_pets',
      key: '41a5bf6bdd81ce963852ccd5a7529353',
    },
  },
  paymentProviders: [
    {
      id: 'stripe',
      method: 'card',
      label: 'Card (Stripe)',
      providerConfig: {
        stripe: {
          publishable_key: 'pk_test_8V022ZFb1roxiosPUVrNu0LU',
        },
      },
    },
    {
      id: 'pcipal',
      method: 'card',
      label: 'Card (PciPal)',
      providerConfig: undefined,
    },
    {
      id: 'gocardless',
      method: 'direct_debit',
      label: 'Direct Debit',
      providerConfig: undefined,
    },
  ],
  globalProductLines: ['cat-dog-pio', 'pet-insurance-uk'],
  claims: {
    docTypes: [
      'MEDICAL_HISTORY',
      'INVOICE',
      'REFERRAL_REPORT',
      'TREATMENT_NOTE',
      'PRESCRIPTION',
      // 'ESTIMATE',
      // 'RECEIPT',
      'OTHER',
    ],
  },
  vetPractices: {
    country: 'UK',
    corporateNetworks: [
      'CVS',
      'Goddards',
      'IVC',
      'Linneaus',
      'Medivet',
      'Pets at Home',
      'VetsNow',
    ],
  },
  paymentTypes: ['UKAccount'],
  customerCountryIsRequired: true,
  customerStateIsRequired: true,
  updatePetStateOnCustomerEdit: false,
  legacyProductLines: ['cat-dog'],
  quotableProductLines: ['cat-dog', 'cat-dog-pio'],
  sgpProductLines: ['pet-insurance-uk'],
  newFlowEnabledProductLines: ['pet-insurance-uk', 'cat-dog-pio'],
  usePcaStateCode: false,
  maxPetAge: 30,
}

export default deepFreeze(agencyConfig)
