import ky from 'ky'
import type { Options, ResponsePromise, SearchParamsOption } from 'ky'
import { isObject, pickBy } from 'lodash-es'

import auth0 from '@/auth0'
import { getConfigForFeature } from '@/lib/appConfig'

export const CLIENT_IDENTIFIER = 'app-io-pas'

const httpMethods = ['get', 'post', 'put', 'patch', 'head', 'delete'] as const
export type HttpMethod = (typeof httpMethods)[number]
type ApiClientFn = {
  (input: string, options?: ApiClientOptions): ResponsePromise
}
export type ApiClient = ApiClientFn & Record<HttpMethod, ApiClientFn>

export type ApiClientOptions = Options

// Copied from https://github.com/sindresorhus/ky/blob/main/source/utils/merge.ts
export const mergeHeaders = (source1 = {}, source2 = {}): HeadersInit => {
  const result = new globalThis.Headers(source1 as HeadersInit)
  const isHeadersInstance = source2 instanceof globalThis.Headers
  const source = new globalThis.Headers(source2 as HeadersInit)

  for (const [key, value] of source.entries()) {
    if ((isHeadersInstance && value === 'undefined') || value === undefined) {
      result.delete(key)
    } else {
      result.set(key, value)
    }
  }

  return result
}

// Copied from https://github.com/sindresorhus/ky/blob/main/source/utils/merge.ts
export const deepMerge = <T>(...sources: Array<Partial<T> | undefined>): T => {
  let returnValue: any = {}
  let headers = {}

  for (const source of sources) {
    if (Array.isArray(source)) {
      if (!Array.isArray(returnValue)) {
        returnValue = []
      }

      returnValue = [...returnValue, ...source]
    } else if (isObject(source)) {
      for (let [key, value] of Object.entries(source)) {
        if (isObject(value) && key in returnValue) {
          value = deepMerge(returnValue[key], value)
        }

        returnValue = { ...returnValue, [key]: value }
      }

      if (isObject((source as any).headers)) {
        headers = mergeHeaders(headers, (source as any).headers)
        returnValue.headers = headers
      }
    }
  }

  return returnValue
}

// If searchParams is an object, we will sanitize it by excluding properties
// that are undefined, null or empty string
const sanitizeSearchParams = (
  searchParams: SearchParamsOption
): SearchParamsOption =>
  isObject(searchParams)
    ? pickBy(
        searchParams as Record<string, string | number | boolean>,
        (value) => value || value === false || value === 0
      )
    : searchParams

// Generic client that we can extend from or use for 3rd-party APIs
export const client = ky.create({
  timeout: 60000,
  retry: 0,
})

const createApiClient = (
  baseUrlFn: () => string,
  defaultOptions: Partial<ApiClientOptions> = {},
  extraOptions: Partial<{ noAuth: boolean }> = {}
): ApiClient => {
  const apiClient: ApiClientFn = (
    input: string,
    options: ApiClientOptions = {}
  ) => {
    const hooks = {
      beforeRequest: [
        async (request: Request): Promise<void> => {
          if (!extraOptions.noAuth) {
            const token = await auth0.getAccessTokenSilently()
            request.headers.set('Authorization', `Bearer ${token}`)
          }
        },
      ],
    }

    return client(
      baseUrlFn() + input,
      deepMerge(
        {
          hooks,
        },
        defaultOptions,
        {
          ...options,
          searchParams: sanitizeSearchParams(options.searchParams),
        }
      )
    )
  }

  for (const method of httpMethods) {
    ;(apiClient as ApiClient)[method] = (
      input: string,
      options: ApiClientOptions = {}
    ): ResponsePromise => apiClient(input, { ...options, method })
  }

  return apiClient as ApiClient
}

export const apiClient: ApiClient = createApiClient(
  () => getConfigForFeature('pioAdminApiUrl') as string
)

export const claimsApiClient: ApiClient = createApiClient(
  () => getConfigForFeature('pioClaimsApiUrl') as string
)

export const shopCompatibilityModeApiClient: ApiClient = createApiClient(
  () => getConfigForFeature('pioShopApiUrl') as string,
  {
    hooks: {
      beforeRequest: [
        (request: Request): void => {
          request.headers.set(
            'x-api-key',
            getConfigForFeature('pioShopAccountApiKey') as string
          )

          // Use baskets v1 (should be the same as setting "x-integration-mode=legacy")
          const featuresHeader = ['basket-v1']

          request.headers.set('x-features', featuresHeader.join(','))
        },
      ],
    },
  }
)

export const shopApiClient: ApiClient = createApiClient(
  () => getConfigForFeature('pioShopApiUrl') as string,
  {
    hooks: {
      beforeRequest: [
        (request: Request): void => {
          request.headers.set(
            'x-api-key',
            getConfigForFeature('pioShopAccountApiKey') as string
          )
        },
      ],
    },
  }
)

export const shopApiClientNoAuth: ApiClient = createApiClient(
  () => getConfigForFeature('pioShopApiUrl') as string,
  {
    hooks: {
      beforeRequest: [
        (request: Request): void => {
          request.headers.set(
            'x-api-key',
            getConfigForFeature('pioShopAccountApiKey') as string
          )
        },
      ],
    },
  },
  { noAuth: true }
)

export const accountApiClient: ApiClient = createApiClient(
  () => getConfigForFeature('pioAccountApiUrl') as string,
  {
    hooks: {
      beforeRequest: [
        (request: Request): void => {
          request.headers.set(
            'x-api-key',
            getConfigForFeature('pioShopAccountApiKey') as string
          )
        },
      ],
    },
  }
)

export const certificateApiClient: ApiClient = createApiClient(
  () => getConfigForFeature('pioAccountApiUrl') as string,
  {
    hooks: {
      beforeRequest: [
        (request: Request): void => {
          request.headers.set(
            'x-api-key',
            getConfigForFeature('pioShopAccountApiKey') as string
          )
          request.headers.set('Accept', 'application/pdf')
        },
      ],
    },
  }
)

export const pioCustomerApiClient: ApiClient = createApiClient(
  () => getConfigForFeature('pioCustomerApiUrl') as string
)
