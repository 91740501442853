import { InstanceConfig } from '@/@types/Config'
import deepFreeze from '@/lib/utils/deepFreeze'
import { AVAILABLE_CLAIM_FLAG_OPTIONS } from '@/novelClaims/domain/Claim'
import { AVAILABLE_LOSS_FLAG_OPTIONS } from '@/novelClaims/domain/Loss'

const instanceConfig: InstanceConfig = {
  isProductionInstance: false,
  pioAdminApiUrl: 'https://admin-api.us.stagpolicies.io',
  pioShopApiUrl: 'https://shop.us-test.policies.io',
  pioClaimsApiUrl: 'https://claim-internal.us-test.policies.io',
  pioShopAccountApiKey: 'Fzgl55Y0kWacvnF4kM6aE42Onnqmz00X1oGcbchR',
  pioAccountApiUrl: 'https://account.us-test.policies.io',
  pioCustomerApiUrl: 'https://customer.us-test.policies.io',
  useTimelines: false,
  agencySecretKeyArn: '',
  allowEditVetPracticeByNonSupervisor: true,
  defaultClaimNoteCategory: 'GENERAL',
  autoPopulateTreatmentEndDate: true,
  'claim-document-request.is-enabled': true,
  'vet-practice.general-email.is-required': false,
  'policy-limits-warnings.is-enabled': false,
  'claim-pet-waiting-period-messages.is-enabled': true,
  'page-viewers.is-enabled': true,
  'claim-display-policy-discrepancies.is-enabled': false,
  'claim-medical-history-conditions.is-enabled': false,
  'claim-millie-status.is-enabled': false,
  'claim-inner-limits.is-enabled': true, // managed in LaunchDarkly
  'vet-practice.vet-users.is-enabled': false,
  allowMultiLossCoverageCalculation: true,
  trackedPolicyCoverageSubcoverages: {},
  claimFlagOptions: [
    AVAILABLE_CLAIM_FLAG_OPTIONS.APPROVED_UNDER_EXCESS,
    AVAILABLE_CLAIM_FLAG_OPTIONS.COMPLAINT,
    AVAILABLE_CLAIM_FLAG_OPTIONS.DECEASED,
    AVAILABLE_CLAIM_FLAG_OPTIONS.FRAUD_THIRD_PARTY_NON_CUSTOMER,
    AVAILABLE_CLAIM_FLAG_OPTIONS.HIGH_VALUE,
    AVAILABLE_CLAIM_FLAG_OPTIONS.INVESTIGATION_NEEDED,
    AVAILABLE_CLAIM_FLAG_OPTIONS.POTENTIAL_RECOVERY,
    AVAILABLE_CLAIM_FLAG_OPTIONS.PRE_EXISTING,
    AVAILABLE_CLAIM_FLAG_OPTIONS.REOPENED,
    AVAILABLE_CLAIM_FLAG_OPTIONS.VULNERABLE_CUSTOMER,
  ],
  lossFlagOptions: [AVAILABLE_LOSS_FLAG_OPTIONS.APPROVED_UNDER_EXCESS],
  vetIntegrations: [],
  websocketApiUrl: 'wss://6cwev64ft6.execute-api.us-east-1.amazonaws.com/test',
  heap: {
    environmentId: '2617005862',
    'is-enabled': true,
  },
}

export default deepFreeze(instanceConfig)
